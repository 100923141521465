import React from "react";
// import Slider from "react-slick";

import Headline from "global/Headline";
// import BlueCard from 'components/BlueCard'

const SolutionSlider = ({ body, link, linkText, title, image }) => {

	// const settings = {
	// 	arrows: true,
	// 	dots: false,
	// 	infinite: true,
	// 	speed: 500,
	// 	slidesToShow: 4,
	// 	slidesToScroll: 1
	// };

	// const mobileSettings = {
	// 	arrows: true,
	// 	dots: false,
	// 	infinite: true,
	// 	speed: 500,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1
	// };

	// const Service = service => (
	// 	<BlueCard {...service} />
	// )

	return (
		<section id="solution-slider">
			<Headline {...{ body, link, linkText, title, image }} />
			{/* {solutions?.length &&
				<div className="services">
					<div className="grid-container">
						<div className="grid-x grid-padding-x">
							<div className="cell small-12">
								<div className="show-for-small-only">
									<Slider {...mobileSettings}>
										{solutions?.length && solutions.map((service, index) => (
											<Service key={`service-mobile-${index}`} {...service} />
										))}
									</Slider>
								</div>
								<div className="hide-for-small-only">
									<Slider {...settings}>
										{solutions?.length && solutions.map((service, index) => (
											<Service key={`service-desktop-${index}`} {...service} />
										))}
									</Slider>
								</div>
							</div>
						</div>
					</div>
				</div>
			} */}
		</section>
	)
}

export default SolutionSlider;