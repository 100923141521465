import React from "react";

import Link from "components/Link"

const CallToAction = (props) => {
	let { title, description, link, linkText, image } = props;

	return (
		<section id="cta">
			<div className="grid-container fluid">
				<div className="grid-x grid-padding-x small-padding-collapse">
					<div className="cell auto bg-holder" style={{ backgroundImage: `url(${image})` }}></div>
					<div className="cell small-12 medium-5">
						<div className="content-wrapper">
							<div className="content-ext"></div>
							<div className="content-wrapper-inner">
								<div className="title">{title}</div>
								<div className="description">{description}</div>
								<Link className="button" to={link}>{linkText}</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CallToAction;
