

import React from "react";

import Link from 'components/Link';
import ColorCard from 'components/ColorCard';

const PromoLinks = props => {

    const { promos } = props;
    
    return (
        <section id="promo-links">
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    <div className="cell flx">
                        {promos.length && promos.map((i, index) => {
                            return (
                                <div key={`promo-${index}`} className="color-card-holder">
                                    <Link to={i.link}>
                                            <ColorCard {...{ title: i.text }} hideIcon={true} hideBottom={true} />
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PromoLinks