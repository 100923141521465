import * as React from "react"
import { graphql } from 'gatsby';

import Layout from 'templates/Layout';
// import Promos from 'templates/home/Promos';
import PromoLinks from 'templates/home/PromoLinks';
import Masthead from 'templates/home/Masthead';
import SolutionSlider from 'templates/home/SolutionSlider';
// import FeaturedProjects from 'templates/home/FeaturedProjects';
import CallToAction from "global/CallToAction";

import DataProvider from 'providers/data';

class IndexPage extends React.Component {
	constructor(props) {
		super(props);
		this.recaptchaComplete = this.recaptchaComplete.bind(this);
		this.state = {
			recaptcha: '',
		}
	}

	recaptchaComplete(value) {
		this.setState({
			recaptcha: value,
		});
	}

	render() {

		const { edges } = this.props.data.allContentPage;
		const { edges: mastheadEdges } = this.props.data.allMastheadSlide;
		// const { edges: districtEges } = this.props.data.allDistrictsDistrict;
		const nodes = edges.map(({ node }) => node);
		const mastheads = mastheadEdges.map(({ node }) => node);
		// let districts = districtEges.map(({ node }) => node);
		// let projects = [];

		// for (const district of districts) {
		// 	let districtProjects = district.Project.map(item => item.object);
		// 	districtProjects = districtProjects.map(item => ({...item, District: district}));
		// 	projects = projects.concat(districtProjects);
		// }

		let page = nodes[0];
		if ((this.props.providerResponse.data.length > 0 || !!page !== false) && this.props.providerStatusText === 'LOADED') {
			page = this.props.providerResponse.data[0];
		}
		const solutionData = {
			body: page.solutionBody,
			link: page.solutionLink,
			linkText: page.solutionLinkText,
			title: page.solutionTitle,
			image: page.solutionImage,
			solutions: page.solutions
		}

		const ctaData = {
			description: page.ctaBody,
			link: page.ctaLink,
			linkText: page.ctaLinkText,
			title: page.ctaTitle,
			image: page.ctaImage,
		}

		const promos = [
			{
				link: page.promo1Link,
				text: page.promo1LinkText,
			},
			{
				link: page.promo2Link,
				text: page.promo2LinkText,
			},
			{
				link: page.promo3Link,
				text: page.promo3LinkText,
			},
		]

		return (
			<Layout showContactForm={page.showContactForm} showNewsletterSignUp={page.showNewsletterSignUp} hoverboards={page.hoverboards}>
				{/* <Promos /> */}
				<Masthead data={mastheads} />
				<PromoLinks promos={promos} />
				{/* <FeaturedProjects projects={projects} districts={districts} /> */}
				<SolutionSlider {...solutionData} />
				<CallToAction {...ctaData} />
			</Layout>
		)
	}
}

// export default IndexPage
const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ContentPage" apiParams={{ uri: "/" }}><IndexPage {...props} /></DataProvider>
export default getData;

export const query = graphql`
{
	allContentPage(
	  filter: {uri: {eq: "/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  showContactForm
		  showNewsletterSignUp
		  solutionBody
		  solutionLink
		  solutionLinkText
		  solutionTitle
		  solutionImage
		  solutions
		  ctaBody
		  ctaLink
		  ctaLinkText
		  ctaTitle
		  ctaImage
		  promo1Link
		  promo1LinkText
		  promo2Link
		  promo2LinkText
		  promo3Link
		  promo3LinkText
		  hoverboards
		}
	  }
	}
	allMastheadSlide {
	  edges {
		node {
		  image
		  title
		  description
		  linkText
		  link
		}
	  }
	}
	allDistrictsDistrict(
	  filter: {Project: {elemMatch: {object: {featured: {eq: true}}}}}
	) {
		edges {
		  node {
			title
			permalink
			Project {
			  object {
				title
				types
				permalink
				description
				mapX
				mapY
				mapLabel
				featured
			  }
			}
		  }
		}
	  }
  }
`
